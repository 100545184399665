import React from "react";
import * as TableStyles from "../../../../themes/TableTheme";
import GeneralRowActions from "../GeneralRowActions";

interface snippetRowProps {
  index: number;
  title: string;
  type: string;
  description: string;
  tags: string;
  editMethod: (recordId: number) => void;
  deleteMethod: (recordId: number) => void;
  viewMethod: (recordId: number) => void;
}

function SnippetRow({
  index,
  title,
  type,
  description,
  tags,
  editMethod,
  viewMethod,
  deleteMethod,
}: snippetRowProps) {
  return (
    <TableStyles.StyledTableRow id="rowData" key={index}>
      <TableStyles.StyledTableCell align="left" component="th" scope="row">
        {title}
      </TableStyles.StyledTableCell>
      <TableStyles.StyledTableCell align="center">
        {description}
      </TableStyles.StyledTableCell>
      <TableStyles.StyledTableCell align="center">
        {tags}
      </TableStyles.StyledTableCell>
      <TableStyles.StyledTableCell align="center">
        {type}
      </TableStyles.StyledTableCell>
      <TableStyles.StyledTableCell align="right">
        <GeneralRowActions
          index={index}
          indexName={title}
          editAction={editMethod}
          deleteAction={deleteMethod}
          viewAction={viewMethod}
        />
      </TableStyles.StyledTableCell>
    </TableStyles.StyledTableRow>
  );
}

export default SnippetRow;
