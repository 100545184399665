import ReactTooltip from "react-tooltip";
import "./GeneralRowActions.css";

interface generalRowActionProps {
  index: number;
  editAction: any;
  deleteAction: any;
  viewAction: any;
  indexName: string;
}

function GeneralRowActions({
  index,
  editAction,
  deleteAction,
  viewAction,
  indexName,
}: generalRowActionProps) {
  return (
    <div>
      <ReactTooltip />
      <span
        className="w3-bar-item w3-button"
        data-tip="View"
        onClick={() => viewAction(index)}
      >
        <i className="viewColor iconSize fa fa-eye"></i>
      </span>
      <span
        className="w3-bar-item w3-button"
        data-tip="Edit"
        onClick={() => editAction(index)}
      >
        <i className="editColor iconSize fa fa-pencil"></i>
      </span>
      <span
        className="w3-bar-item w3-button"
        data-tip="Delete"
        onClick={() => deleteAction(index, indexName)}
      >
        <i className="deleteColor iconSize fa fa-times-circle-o"></i>
      </span>
    </div>
  );
}

export default GeneralRowActions;
