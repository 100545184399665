/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@material-ui/core";
import * as Models from "../../../models/GlobalModels";
import useApi from "../../../api/useApi";
import "./DeletionModal.css";

interface DeletionProps {
  modalProps: Models.modalData;
  objectModel: any;
  hideModalMethod: React.Dispatch<React.SetStateAction<Models.modalData>>;
  responseMethod: (response: Models.postResponse, deletion: boolean) => void;
}

function DeletionModal({
  modalProps,
  objectModel,
  hideModalMethod,
  responseMethod,
}: DeletionProps) {
  const [loadError, setLoadError] = useState();
  const axios = useApi();
  const title = (
    modalProps.type === "note" ? "Delete This Note?" : "Delete This Snippet?"
  ) as string;

  const closeModal = () => {
    hideModalMethod({ title: "", visible: false, type: "" });
  };

  const deleteItem = (objId: number) => {
    const request = (
      modalProps.type === "note"
        ? `/Notes/DeleteNote/${objId}`
        : `/Snippet/DeleteSnippet/${objId}`
    ) as string;

    axios
      .delete(request)
      .then((response) => {
        const result = response.data as Models.postResponse;
        responseMethod(result, true);
      })
      .catch((error: Error) => {
        setLoadError(() => {
          throw error;
        });
      });
  };

  const displayIconCss = `delIconProps fa fa-${
    modalProps.type === "note" ? "sticky-note" : "scissors"
  }` as string;

  return (
    <Modal show={modalProps.visible} backdrop="static" size="lg">
      <Modal.Header>
        <h2>{title}</h2>
      </Modal.Header>
      <Modal.Body>
        <p className="centerText textProps">
          Are you sure you want to delete this {objectModel.title}?
        </p>
        <p className="centerText">
          <i className={displayIconCss}></i>
        </p>
        <p className="centerText warnText">Warning: This cannot be undone!</p>
      </Modal.Body>
      <Modal.Footer className="controlSpacing">
        <Button
          onClick={() => closeModal()}
          variant="contained"
          color="primary"
        >
          Cancel
        </Button>

        <Button
          className="submitBtn"
          onClick={() => deleteItem(objectModel.id)}
          variant="contained"
          color="secondary"
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeletionModal;
