import React from "react";
import loadingImg from "../../assets/SmallCircle.gif";
import "./ComLoading.css";

function ComLoading() {
  return (
    <div className="loadingContainerProps">
      <img className="comCircleProps" src={loadingImg} alt="Loading Spinner" />
    </div>
  );
}

export default ComLoading;
