export interface Snippet {
  id: number;
  title: string;
  description: string;
  snippetText: string;
  tags: string;
  type: string;
  dateCreated: string;
  dateModified: string;
  createdBy: string;
}

export interface SnippetPayload {
  success: boolean;
  snippetList: Snippet[];
  count: number;
}

export interface postResponse {
  success: boolean;
  message: string;
}

export const defaultSnippetPayload = {
  success: true,
  snippetList: [],
  count: 0,
} as SnippetPayload;

export const defaultSnippet = {
  id: 0,
  title: "",
  tags: "",
  type: "",
  dateCreated: "",
  dateModified: "",
  createdBy: "",
} as Snippet;

export interface modalData {
  visible: Boolean;
  title: string;
  type: string;
}

export interface tagsPayload {
  success: boolean;
  tags: string[];
}

export interface snippetApiResult {
  success: boolean;
  snippet: Snippet;
}

export interface pagingModel {
  startValue: number;
  maxValue: number;
  index: number;
}

export interface linkSelection {
  code: string;
  notes: string;
}

//Notes

export interface Note {
  id: number;
  title: string;
  content: string;
  noteType: string;
  created: string;
  createdBy: string;
  modified: string;
  modifiedBy: string;
}

export interface NotePayload {
  success: boolean;
  noteList: Note[];
  count: number;
}

export const defaultNotePayload = {
  success: true,
  noteList: [],
  count: 0,
} as NotePayload;

export const defaultNote = {
  id: 0,
  title: "",
  content: "",
  noteType: "",
  created: "",
  createdBy: "",
  modified: "",
  modifiedBy: "",
} as Note;

export interface noteApiResult {
  success: boolean;
  noteObj: Note;
}

//Profiles

export interface userProfile {
  id: number;
  username: string;
  avatar: string;
  created: string;
}

export interface AppMessage {
  message: string;
  messageType: string;
}

export const defaultProfile = {
  id: 0,
  username: "",
  avatar: "",
  created: "",
} as userProfile;

export interface avatarSubmitWrapper {
  avatar: string;
}

export interface SentinelResponse {
  success: boolean;
  eventID: string;
}

export interface LogEvent {
  clientID: string;
  username: string;
  system: string;
  machineName: string;
  ipDaddress: string;
  publicIPAddress: string;
  urlAddress: string;
  applicationName: string;
  platform: string;
  version: string;
  errorMessage: string;
  innerException: string;
  stackTrace: string;
  errorType: string;
  tagName: string;
  metaDataProps: string;
}
