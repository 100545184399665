import axios from "axios";
import React from "react";
import ErrorPage from "../error/Error";
import * as EnvMgr from "../../utility/envUtil";
import * as Models from "../../models/GlobalModels";

interface MyProps {
  children: any;
}

interface MyState {
  error: any;
  eventId: any;
}

class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });

    const appKey = "3abe0476-ab9c-4821-a687-149bd63d6559" as string;
    const envName = process.env.REACT_APP_ENVIRONMENT_NAME as string;
    const isLocal: boolean = ["Local"].includes(envName);

    const osName = (
      EnvMgr.isLocalEnvironment() ? "Microsoft Windows" : "Ubuntu Linux"
    ) as string;

    if (!isLocal) {
      const event = {
        clientID: appKey,
        username: "System",
        system: osName,
        applicationName: "Gemini FE",
        platform: "ANTF Zen",
        errorMessage: error.message.toString(),
      } as Models.LogEvent;

      const request =
        "https://servicestar.antfcorp.com/api/v1/SentinelEvents/LogErrorEvent" as string;

      axios
        .post(request, event)
        .then((response) => {
          const result = response.data as Models.SentinelResponse;
          if (result.success) {
            const eventId = result.eventID.toString();
            this.setState({ eventId });
          }
        })
        .catch((error) => {
          //Do nothing TBD
          const eventId = "Unknown Error";
          this.setState({ eventId });
        });
    } else {
      const eventId = "Mock Error 123";
      this.setState({ eventId });
    }
  }

  render() {
    const { error, eventId } = this.state;
    const { children } = this.props;

    // Render fallback UI
    if (error) {
      return <ErrorPage statusMessage={error.message} eventID={eventId} />;
    }
    // When there's not an error, render the children untouched
    return children;
  }
}

export default ErrorBoundary;
