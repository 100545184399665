import * as Models from "../models/GlobalModels";

export function validateNoteForSubmission(payload: Models.Note): string {
  let errorReasons = "";
  if (payload.title === "" || payload === undefined) {
    errorReasons = errorReasons + "Title cannot be empty. ";
  }

  if (payload.content === "" || payload.content === undefined) {
    errorReasons = errorReasons + "Note text cannot be empty. ";
  }

  if (payload.noteType === "" || payload.noteType === undefined) {
    errorReasons = errorReasons + "Must define the type of note. ";
  }

  return errorReasons;
}
