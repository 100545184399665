import React from "react";
import "./Error.css";

interface ErrorProps {
  statusMessage: string;
  eventID: string;
}

function Error({ statusMessage, eventID }: ErrorProps) {
  const errorText = `Reason: ${statusMessage}.`;
  const sentryCode = `$Error ID: ${eventID} | Support Contact: support@antfcorp.com or servicestar.antfcorp.com`;

  return (
    <>
      <p className="centerText errorIconSize">
        <i className="fa fa-warning"></i>
      </p>
      <div className="center">
        <h1 className="headerFont">Whoops</h1>
        <p className="textfont">
          An Error Occured while attempting to communicate with the server
        </p>
        <p className="textfont">{errorText}</p>
        <p className="textfont">{sentryCode}</p>
      </div>
    </>
  );
}

export default Error;
