/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, TableBody, TableContainer, Button } from "@material-ui/core";
import SnippetTableHeaders from "../../components/tables/headers/SnippetTableHeaders";
import * as Models from "../../models/GlobalModels";
import SnippetRow from "../../components/tables/rows/snippets/snippetRow";
import NoSnippet from "../../components/tiles/noSnippet/NoSnippet";
import useApi from "../../api/useApi";
import SnippetModal from "../../components/modals/snippetModal/SnippetModal";
import DeletionModal from "../../components/modals/deletionModal/DeletionModal";
import ComLoading from "../../components/comLoading/ComLoading";
import Pager from "../../components/pager/Pager";
import "./Snippets.css";

interface snippetPageProps {
  notifier: (message: string, success: boolean) => void;
  refreshFunc: React.Dispatch<React.SetStateAction<boolean>>;
  selTag: string;
  loadingViewFunc: React.Dispatch<React.SetStateAction<boolean>>;
  loadingView: boolean;
  errorHandlerFunc: React.Dispatch<React.SetStateAction<Error | undefined>>;
}

function Snippets({
  notifier,
  selTag,
  refreshFunc,
  loadingView,
  loadingViewFunc,
  errorHandlerFunc,
}: snippetPageProps) {
  const [snippetObjects, setSnippetObjects] = useState<Models.SnippetPayload>({
    success: true,
    snippetList: [],
    count: 0,
  });

  const maxPaging = 5 as number;

  const [selectedSnippet, setSelectedSnippet] = useState<Models.Snippet>(
    Models.defaultSnippet
  );

  const [snippetModalView, setSnippetModalView] = useState<Models.modalData>({
    title: "",
    visible: false,
    type: "",
  });

  const [deleteView, setDeleteView] = useState<Models.modalData>({
    title: "",
    visible: false,
    type: "",
  });

  const [pageIndex, setPageIndex] = useState<Models.pagingModel>({
    startValue: 0,
    maxValue: 5,
    index: 1,
  });

  const axios = useApi();

  const getSnippets = () => {
    loadingViewFunc(false);
    const request = `/Snippet/${
      selTag.toLowerCase() !== "all"
        ? `GetSnippetsByTag/${selTag}`
        : "GetAllSnippets"
    }` as string;

    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.SnippetPayload;
        loadingViewFunc(true);
        setSnippetObjects(result);
      })
      .catch((error: Error) => {
        loadingViewFunc(true);
        errorHandlerFunc(() => {
          throw error;
        });
      });
  };

  const postResponse = (response: Models.postResponse, deletion: boolean) => {
    if (response.success) {
      if (deletion) {
        setDeleteView({ title: "", visible: false, type: "" });
      } else {
        setSnippetModalView({ title: "", visible: false, type: "" });
      }
      getSnippets();
      refreshFunc(true);
    }
    notifier(response.message, response.success);
  };

  const fetchSelectedSnippet = (snipId: number) => {
    const request = `/Snippet/GetSnippet/${snipId}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.snippetApiResult;
        setSelectedSnippet(result.snippet);
      })
      .catch((error: Error) => {
        errorHandlerFunc(() => {
          throw error;
        });
      });
  };

  const editSnippet = (recordId: number) => {
    fetchSelectedSnippet(recordId);
    setSnippetModalView({
      title: "Edit My Snippet",
      visible: true,
      type: "Edit",
    });
  };

  const deleteSnippet = (recordId: number) => {
    fetchSelectedSnippet(recordId);
    setDeleteView({
      title: "",
      visible: true,
      type: "snippet",
    });
  };

  const viewSnippet = (recordId: number) => {
    fetchSelectedSnippet(recordId);
    setSnippetModalView({
      title: "View My Snippet",
      visible: true,
      type: "View",
    });
  };

  const newSnippetBtn = () => {
    setSelectedSnippet(Models.defaultSnippet);
    setSnippetModalView({
      title: "My New Snippet",
      visible: true,
      type: "New",
    });
  };

  useEffect(() => {
    getSnippets();
  }, [selTag]);

  return (
    <div>
      {loadingView ? (
        <div>
          <Button
            className="newButtonProps"
            variant="contained"
            color="primary"
            onClick={() => newSnippetBtn()}
          >
            New Snippet
          </Button>

          <br />

          <div className="tableContainer">
            <SnippetModal
              notificationFunc={notifier}
              modalProps={snippetModalView}
              hideModalMethod={setSnippetModalView}
              selectedSnippet={selectedSnippet}
              responseMethod={postResponse}
            />

            <DeletionModal
              objectModel={selectedSnippet}
              modalProps={deleteView}
              hideModalMethod={setDeleteView}
              responseMethod={postResponse}
            />

            {snippetObjects !== undefined &&
            snippetObjects.snippetList.length === 0 ? (
              <NoSnippet />
            ) : (
              <>
                <TableContainer
                  className="mobileTable tablePadding"
                  component={Paper}
                >
                  <Table className="dailyTable" aria-label="caption table">
                    <SnippetTableHeaders />
                    <TableBody>
                      {snippetObjects?.snippetList
                        .slice(pageIndex.startValue, pageIndex.maxValue)
                        .map((snip) => (
                          <SnippetRow
                            key={Math.random()}
                            index={snip.id}
                            title={snip.title}
                            type={snip.type}
                            tags={snip.tags}
                            description={snip.description}
                            editMethod={editSnippet}
                            deleteMethod={deleteSnippet}
                            viewMethod={viewSnippet}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {snippetObjects.count > maxPaging ? (
                  <div className="pagingLoc">
                    <Pager
                      maxCount={snippetObjects.count}
                      indexModel={pageIndex}
                      indexModelFunc={setPageIndex}
                      pagingAmount={maxPaging}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <ComLoading />
      )}
    </div>
  );
}

export default Snippets;
