import React, { useEffect, useState } from "react";
import Logo from "../../assets/Gemini.png";
import * as TextUtils from "../../utility/TextUtilities";
import { useHistory } from "react-router-dom";
import * as Models from "../../models/GlobalModels";
import { useKeycloak } from "@react-keycloak/web";
import "./SideBar.css";

interface SideBarProperties {
  sideBarState: boolean;
  sideBarToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setPageNameFunc: React.Dispatch<React.SetStateAction<string>>;
  refreshFunc: React.Dispatch<React.SetStateAction<boolean>>;
}

function SideBar({
  sideBarState,
  sideBarToggle,
  setPageNameFunc,
  refreshFunc,
}: SideBarProperties) {
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const [selectedLink, setSelectedLink] = useState<Models.linkSelection>({
    code: "w3-text-teal",
    notes: "",
  });

  const linkCss = "w3-bar-item w3-button w3-padding" as string;

  const onLinkClick = (pageName: string) => {
    let page = `./${pageName}` as string;
    if (pageName === "home") {
      page = "./";
      setPageNameFunc("Home");
    } else {
      setPageNameFunc(TextUtils.filterPageName(page));
    }

    setNavLink(pageName);

    history.push(page);
    refreshFunc(true);
  };

  const setNavLink = (val: string) => {
    if (val === "notes") {
      setSelectedLink({ code: "", notes: "w3-text-teal" });
    } else {
      setSelectedLink({ code: "w3-text-teal", notes: "" });
    }
  };

  const openURLInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    const firstTimeLoc = (
      window.location.href.includes("/notes") ? "notes" : "snippets"
    ) as string;

    setNavLink(firstTimeLoc);
  }, []);

  return (
    <div>
      {sideBarState ? (
        <nav className="w3-sidebar w3-white sidebarProps">
          <br />
          <div className="w3-container">
            <p className="w3-hide-large w3-right sidebarClose w3-padding w3-hover-grey">
              <i
                className="fa fa-remove"
                title="close menu"
                onClick={() => sideBarToggle(false)}
              ></i>
            </p>

            <img
              src={Logo}
              className="w3-round sideBarAvatar"
              alt="Avatar Img"
            />

            <br />
            <br />

            <h4>
              <b>ANTF Gemini</b>
            </h4>
          </div>

          <div className="w3-bar-block">
            <p
              onClick={() => onLinkClick("snippets")}
              className={`${linkCss} ${selectedLink.code}`}
            >
              <i className="fa fa-scissors fa-fw w3-margin-right linkSize"></i>
              Code Snippets
            </p>
            <p
              onClick={() => onLinkClick("notes")}
              className={`${linkCss} ${selectedLink.notes}`}
            >
              <i className="fa fa-sticky-note fa-fw w3-margin-right linkSize"></i>
              My Notes
            </p>
            <p
              className="w3-bar-item w3-button w3-padding"
              onClick={() => openURLInNewTab("https://antfcorp.com/Support")}
            >
              <i className="fa fa-envelope fa-fw w3-margin-right linkSize"></i>
              Support
            </p>
          </div>

          <div className="w3-panel w3-large">
            <i
              className="fa fa-sign-out socialMediaProps sidebarClose w3-hover-opacity"
              title="logout"
              onClick={() => keycloak.logout()}
            ></i>
          </div>
        </nav>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SideBar;
