import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { Table, TableBody, TableContainer, Button } from "@material-ui/core";
import ComLoading from "../../components/comLoading/ComLoading";
import * as Models from "../../models/GlobalModels";
import useApi from "../../api/useApi";
import Pager from "../../components/pager/Pager";
import NoteTableHeader from "../../components/tables/headers/NoteTableHeader";
import NoteRow from "../../components/tables/rows/notes/NoteRow";
import NoteModal from "../../components/modals/noteModal/NoteModal";
import DeletionModal from "../../components/modals/deletionModal/DeletionModal";
import NoNote from "../../components/tiles/noNote/NoNote";
import "./Notes.css";

interface notesProps {
  loadingView: boolean;
  loadingViewFunc: React.Dispatch<React.SetStateAction<boolean>>;
  notifier: (message: string, success: boolean) => void;
  selTag: string;
  errorHandlerFunc: React.Dispatch<React.SetStateAction<Error | undefined>>;
}

function Notes({
  loadingView,
  loadingViewFunc,
  notifier,
  selTag,
  errorHandlerFunc,
}: notesProps) {
  const maxPaging = 5 as number;

  const [noteObjects, setNoteObjects] = useState<Models.NotePayload>({
    success: true,
    noteList: [],
    count: 0,
  });

  const [noteModalView, setNoteModalView] = useState<Models.modalData>({
    title: "",
    visible: false,
    type: "",
  });

  const [deleteView, setDeleteView] = useState<Models.modalData>({
    title: "",
    visible: false,
    type: "",
  });

  const [selectedNote, setSelectedNote] = useState<Models.Note>(
    Models.defaultNote
  );

  const [pageIndex, setPageIndex] = useState<Models.pagingModel>({
    startValue: 0,
    maxValue: 5,
    index: 1,
  });

  const axios = useApi();

  const getNotes = () => {
    loadingViewFunc(false);

    const request = `/Notes/${
      selTag.toLowerCase() !== "all"
        ? `GetAllNotesByType/${selTag}`
        : "GetAllNotes"
    }` as string;

    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.NotePayload;
        loadingViewFunc(true);
        setNoteObjects(result);
      })
      .catch((error: Error) => {
        loadingViewFunc(true);
        errorHandlerFunc(() => {
          throw error;
        });
      });
  };

  const postResponse = (response: Models.postResponse, deletion: boolean) => {
    if (response.success) {
      if (deletion) {
        setDeleteView({ title: "", visible: false, type: "" });
      } else {
        setNoteModalView({ title: "", visible: false, type: "" });
      }
      getNotes();
    }
    notifier(response.message, response.success);
  };

  const fetchSelectedNote = (noteId: number) => {
    const request = `/Notes/GetNote/${noteId}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.noteApiResult;
        setSelectedNote(result.noteObj);
      })
      .catch((error: Error) => {
        errorHandlerFunc(() => {
          throw error;
        });
      });
  };

  const editNote = (recordId: number) => {
    fetchSelectedNote(recordId);
    setNoteModalView({
      title: "Edit My Note",
      visible: true,
      type: "Edit",
    });
  };

  const deleteNote = (recordId: number) => {
    fetchSelectedNote(recordId);
    setDeleteView({
      title: "",
      visible: true,
      type: "note",
    });
  };

  const viewNote = (recordId: number) => {
    fetchSelectedNote(recordId);
    setNoteModalView({
      title: "View My Note",
      visible: true,
      type: "View",
    });
  };

  const newNoteBtn = () => {
    setSelectedNote(Models.defaultNote);
    setNoteModalView({
      title: "My New Note",
      visible: true,
      type: "New",
    });
  };

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selTag]);

  return (
    <div>
      {loadingView ? (
        <div>
          <Button
            className="newButtonProps"
            variant="contained"
            color="primary"
            onClick={() => newNoteBtn()}
          >
            New Note
          </Button>

          <br />

          <div className="tableContainer">
            <NoteModal
              notificationFunc={notifier}
              modalProps={noteModalView}
              hideModalMethod={setNoteModalView}
              selectedNote={selectedNote}
              responseMethod={postResponse}
            />

            <DeletionModal
              objectModel={selectedNote}
              modalProps={deleteView}
              hideModalMethod={setDeleteView}
              responseMethod={postResponse}
            />

            {noteObjects !== undefined && noteObjects.noteList.length === 0 ? (
              <NoNote />
            ) : (
              <>
                <TableContainer
                  className="mobileTable tablePadding"
                  component={Paper}
                >
                  <Table className="dailyTable" aria-label="caption table">
                    <NoteTableHeader />
                    <TableBody>
                      {noteObjects?.noteList
                        .slice(pageIndex.startValue, pageIndex.maxValue)
                        .map((note) => (
                          <NoteRow
                            key={Math.random()}
                            index={note.id}
                            title={note.title}
                            type={note.noteType}
                            modified={note.modified}
                            editMethod={editNote}
                            deleteMethod={deleteNote}
                            viewMethod={viewNote}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {noteObjects.count > maxPaging ? (
                  <div className="pagingLoc">
                    <Pager
                      maxCount={noteObjects.count}
                      indexModel={pageIndex}
                      indexModelFunc={setPageIndex}
                      pagingAmount={maxPaging}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <ComLoading />
      )}
    </div>
  );
}

export default Notes;
