import React from "react";
import "./NoSnippet.css";

function NoSnippet() {
  return (
    <div>
      <p className="centerTileText">
        <i className="fa fa-scissors tileIconSize tileColor"></i>
      </p>
      <h3 className="centerTileText tileAlignment tileColor">
        You have no Code Snippets.
      </h3>
    </div>
  );
}

export default NoSnippet;
