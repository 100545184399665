/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ImageUploader from "../../components/imageUploader/ImageUploader";
import useApi from "../../api/useApi";
import * as Models from "../../models/GlobalModels";
import { Row, Col } from "react-bootstrap";
import "./Profile.css";

interface ProfileProps {
  notifier: (message: string, success: boolean) => void;
  refreshProfileFunc: () => void;
  errorHandlerFunc: React.Dispatch<React.SetStateAction<Error | undefined>>;
}

function Profile({
  notifier,
  refreshProfileFunc,
  errorHandlerFunc,
}: ProfileProps) {
  const axios = useApi();

  const [profileImgB64, setProfileImgB64] = useState<string>("");

  const uploadProfileImageToServer = (imgData: string) => {
    const payloadData = { avatar: imgData } as Models.avatarSubmitWrapper;
    axios
      .post("/Profile/UploadNewProfileImage", payloadData)
      .then((response) => {
        const result = response.data as Models.postResponse;
        notifier(result.message, result.success);
        refreshProfileFunc();
        setProfileImgB64("");
      })
      .catch((error: Error) => {
        errorHandlerFunc(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    if (profileImgB64 !== "") {
      uploadProfileImageToServer(profileImgB64);
    }
  }, [profileImgB64]);

  return (
    <>
      <div className="profilePageSpacing">
        <h2>Profile Management</h2>
        <p className="profilePageSpacing">
          <strong>Note:</strong> If you wish to change your password. Logout and
          the option to change the password will be on the login screen.
        </p>
        <Row>
          <Col>
            <p>Change Image Profile Picture</p>
            <ImageUploader
              imageReturnFunc={setProfileImgB64}
              notifier={notifier}
            />
          </Col>
          <Col></Col>
        </Row>
      </div>
    </>
  );
}

export default Profile;
