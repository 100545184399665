import * as Models from "../models/GlobalModels";

export function validateSnippetForSubmission(payload: Models.Snippet): string {
  let errorReasons = "";
  if (payload.title === "" || payload === undefined) {
    errorReasons = errorReasons + "Title cannot be empty. ";
  }

  if (payload.tags === "" || payload.tags === undefined) {
    errorReasons = errorReasons + "Must define at least 1 tag. ";
  }

  if (payload.type === "" || payload.type === undefined) {
    errorReasons =
      errorReasons + "Must define the coding language of the snippet. ";
  }

  if (payload.snippetText === "" || payload.snippetText === undefined) {
    errorReasons = errorReasons + "Snippet code text cannot be empty. ";
  }
  return errorReasons;
}
