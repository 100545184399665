/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SideBar from "./components/sideBar/SideBar";
import Header from "./components/header/Header";
import { Switch, Route } from "react-router-dom";
import Snippets from "./pages/snippets/Snippets";
import Notes from "./pages/notes/Notes";
import useApi from "./api/useApi";
import Profile from "./pages/profile/Profile";
import useWindowDimensions from "./utility/useWindowSize";
import { ToastContainer } from "react-toastify";
import * as Models from "./models/GlobalModels";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/footer/Footer";
import { toast } from "react-toastify";
import "./App.css";
import AttentionBanner from "./components/alert/AttentionBanner";

function App() {
  const [showBar, setShowBar] = useState<boolean>(true);
  const [navPage, setNavPage] = useState<string>("Home");
  const [userProfile, setUserProfile] = useState<Models.userProfile>(
    Models.defaultProfile
  );
  const [selectedTag, setSelectedTag] = useState<string>("ALL");
  const [refreshTags, setRefreshTags] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadError, setLoadError] = useState<Error>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { height, width } = useWindowDimensions();

  const axios = useApi();

  useEffect(() => {
    if (width > 992) {
      if (!showBar) {
        setShowBar(true);
      }
    }
  }, [width]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const showToastNotification = (message: string, success: boolean) => {
    if (success) {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };

  const getUserProfile = () => {
    axios
      .get("/Profile/GetProfileImage")
      .then((response) => {
        const result = response.data as Models.userProfile;
        setUserProfile(result);
      })
      .catch((error: Error) => {
        setLoadError(() => {
          throw error;
        });
      });
  };

  return (
    <div className="w3-light-grey w3-content maxSize">
      <ToastContainer />
      <SideBar
        sideBarState={showBar}
        sideBarToggle={setShowBar}
        setPageNameFunc={setNavPage}
        refreshFunc={setRefreshTags}
      />

      <div className="w3-main appProps">
        <AttentionBanner />
        <Header
          currentPage={navPage}
          sideBarToggle={setShowBar}
          setPageNameFunc={setNavPage}
          setTagFunc={setSelectedTag}
          refresh={refreshTags}
          refreshFunc={setRefreshTags}
          selTag={selectedTag}
          loadingView={loaded}
          errorHandlerFunc={setLoadError}
          profileData={userProfile}
        />
        <div className="globalContainer">
          <div className="appContainer">
            <Switch>
              <Route exact path="/">
                <Snippets
                  notifier={showToastNotification}
                  selTag={selectedTag}
                  refreshFunc={setRefreshTags}
                  loadingView={loaded}
                  loadingViewFunc={setLoaded}
                  errorHandlerFunc={setLoadError}
                />
              </Route>
              <Route exact path="/snippets">
                <Snippets
                  notifier={showToastNotification}
                  selTag={selectedTag}
                  refreshFunc={setRefreshTags}
                  loadingView={loaded}
                  loadingViewFunc={setLoaded}
                  errorHandlerFunc={setLoadError}
                />
              </Route>
              <Route exact path="/notes">
                <Notes
                  loadingView={loaded}
                  loadingViewFunc={setLoaded}
                  notifier={showToastNotification}
                  selTag={selectedTag}
                  errorHandlerFunc={setLoadError}
                />
              </Route>
              <Route exact path="/profile">
                <Profile
                  notifier={showToastNotification}
                  refreshProfileFunc={getUserProfile}
                  errorHandlerFunc={setLoadError}
                />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
