import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import AuthRouter from "./AppAuth";
import "./index.css";

ReactDOM.render(
  <ErrorBoundary>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: "login-required" }}
    >
      <BrowserRouter>
        <AuthRouter />
      </BrowserRouter>
    </ReactKeycloakProvider>
  </ErrorBoundary>,

  document.getElementById("root")
);
