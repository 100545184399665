import React from "react";
import loadingImg from "../../assets/ANTFLoading.gif";
import "./Loading.css";

function Loading() {
  return (
    <div className="centerStage">
      <img className="spinnerSize" src={loadingImg} alt="Loading" />
    </div>
  );
}

export default Loading;
