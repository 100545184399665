import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "./components/loading/Loading";
import App from "./App";

function AuthRouter() {
  const { keycloak } = useKeycloak();

  return <div>{keycloak.authenticated ? <App /> : <Loading />}</div>;
}

export default AuthRouter;
