import { Button } from "@material-ui/core";
import * as ImageUtilities from "../../utility/ImgUtilities";
import React from "react";
import "./ImageUploader.css";

interface ImageUploaderProps {
  imageReturnFunc: React.Dispatch<React.SetStateAction<string>>;
  notifier: (message: string, success: boolean) => void;
}

function ImageUploader({ imageReturnFunc, notifier }: ImageUploaderProps) {
  const onImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const foundFile = e.target.files?.[0] as File;
    if (foundFile !== null && foundFile !== undefined) {
      const validationResult = ImageUtilities.validateFile(
        foundFile,
        1000000
      ) as string;
      if (validationResult.length === 0) {
        const convertedImg = (await ImageUtilities.checkFileBase64(
          foundFile
        )) as string;
        imageReturnFunc(convertedImg);
      } else {
        notifier(validationResult, false);
      }
    }
  };

  return (
    <div className="appContainer">
      <Button variant="contained" component="label" color="primary">
        Upload File
        <input onChange={(e) => onImageUpload(e)} type="file" hidden />
      </Button>
    </div>
  );
}

export default ImageUploader;
