import { TableHead, TableRow } from "@material-ui/core";
import * as TableStyles from "../../../themes/TableTheme";

function SnippetTableHeaders() {
  return (
    <TableHead>
      <TableRow>
        <TableStyles.StyledTableCell>
          Snippet&nbsp;Name
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Description
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Tags
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="center">
          Type
        </TableStyles.StyledTableCell>
        <TableStyles.StyledTableCell align="right">
          Actions
        </TableStyles.StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default SnippetTableHeaders;
