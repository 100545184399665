import React from "react";
import "./Editor.css";

interface EditorProps {
  value: string;
  controlName: string;
  css: string;
  readOnly: boolean;
  handleChange: any;
  handleBlur: any;
}

function Editor({
  controlName,
  value,
  css,
  readOnly,
  handleChange,
  handleBlur,
}: EditorProps) {
  const cssItems = `edittextarea ${css}` as string;

  return (
    <div className="textarea-wrapper">
      <textarea
        id={controlName}
        name={controlName}
        className={cssItems}
        rows={10}
        cols={60}
        disabled={readOnly}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
    </div>
  );
}

export default Editor;
