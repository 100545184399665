import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import * as Models from "../../../models/GlobalModels";
import "./TagsDropdown.css";

interface TagsSelectProps {
  tags: Models.tagsPayload;
  selectedTag: string;
  handleSelection: (tag: string) => void;
}

function TagsDropdown({ tags, handleSelection, selectedTag }: TagsSelectProps) {
  const selectedItem = (event: any) => {
    if (event !== null && event !== undefined) {
      const val = event.toString() as string;
      handleSelection(val);
    }
  };

  return (
    <>
      <FormControl className="controlTagSize">
        <InputLabel required>Tags</InputLabel>
        <Select
          name="tagSelector"
          value={selectedTag}
          onChange={(e) => selectedItem(e.target.value)}
        >
          <MenuItem value={"ALL"}>ALL</MenuItem>
          {tags?.tags.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default TagsDropdown;
