import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Row, Col } from "react-bootstrap";
import * as Models from "../../models/GlobalModels";
import "./Pager.css";

interface PagerProps {
  maxCount: number;
  indexModel: Models.pagingModel;
  indexModelFunc: React.Dispatch<React.SetStateAction<Models.pagingModel>>;
  pagingAmount: number;
}

function Pager({
  maxCount,
  indexModel,
  indexModelFunc,
  pagingAmount,
}: PagerProps) {
  const pageCount = Math.ceil(maxCount / pagingAmount) as number;

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const plusJumpVal = pagingAmount * value;
    const plusMoveVal = value * pagingAmount - pagingAmount;
    if (value !== indexModel.index) {
      if (value > indexModel.index) {
        const newPageModel = {
          startValue: plusMoveVal,
          maxValue: plusJumpVal,
          index: value,
        } as Models.pagingModel;
        indexModelFunc(newPageModel);
      } else {
        const newPageModel = {
          startValue: plusMoveVal,
          maxValue: plusJumpVal,
          index: value,
        } as Models.pagingModel;
        indexModelFunc(newPageModel);
      }
    }
  };

  return (
    <>
      <Row>
        <Col sm={3} md={2} lg={1}>
          <p className="pagingLabel">Count: {maxCount}</p>
        </Col>
        <Col>
          <Stack spacing={2} className="pagingContainer">
            <Pagination
              count={pageCount}
              page={indexModel.index}
              color="primary"
              onChange={handleChange}
            />
          </Stack>
        </Col>
      </Row>
    </>
  );
}

export default Pager;
