export const codingLanguages = [
  "C#",
  "VB.Net",
  "F#",
  "XAML",
  "Java",
  "Javascript",
  "Dart",
  "Flutter",
  "Go",
  "C",
  "C++",
  "PhP",
  "Python",
  "Ruby",
  "Perl",
  "Swift",
  "Kotlin",
  "Objective C",
  "Rust",
  "Lua",
  "Lisp",
  "OCamel",
  "Scala",
  "Matlab",
] as string[];
