/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as NoteCategories from "../../../data/NoteCat";
import * as ValidationUtils from "../../../validations/NoteValidations";
// import Highlight from "react-highlight";
import useApi from "../../../api/useApi";
import "./NoteModal.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import * as Models from "../../../models/GlobalModels";

interface NoteModalProps {
  modalProps: Models.modalData;
  hideModalMethod: React.Dispatch<React.SetStateAction<Models.modalData>>;
  selectedNote: Models.Note;
  notificationFunc: (message: string, success: boolean) => void;
  responseMethod: (response: Models.postResponse, deletion: boolean) => void;
}

function NoteModal({
  modalProps,
  hideModalMethod,
  selectedNote,
  responseMethod,
  notificationFunc,
}: NoteModalProps) {
  const [loadError, setLoadError] = useState();
  const axios = useApi();

  const noteTypes = NoteCategories.noteTypes;

  const isReadOnly = (modalProps.type === "View") as boolean;

  const closeModal = () => {
    hideModalMethod({ title: "", visible: false, type: "" });
  };

  const submitFormData = (payload: Models.Note) => {
    const validations = ValidationUtils.validateNoteForSubmission(
      payload
    ) as string;
    if (validations === "") {
      if (modalProps.type === "Edit") {
        updateNote(payload);
      } else {
        createNote(payload);
      }
    } else {
      notificationFunc(validations, false);
    }
  };

  const createNote = (payload: Models.Note) => {
    axios
      .post("/Notes/CreateNote", payload)
      .then((response) => {
        const result = response.data as Models.postResponse;
        responseMethod(result, false);
      })
      .catch((error: Error) => {
        setLoadError(() => {
          throw error;
        });
      });
  };

  const updateNote = (payload: Models.Note) => {
    axios
      .put("/Notes/UpdateNote", payload)
      .then((response) => {
        const result = response.data as Models.postResponse;
        responseMethod(result, false);
      })
      .catch((error: Error) => {
        setLoadError(() => {
          throw error;
        });
      });
  };

  return (
    <Modal
      show={modalProps.visible}
      onHide={hideModalMethod}
      backdrop="static"
      size="lg"
    >
      <Modal.Header>
        <h2>{modalProps.title}</h2>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{
            id: selectedNote.id || 0,
            title: selectedNote.title || "",
            noteType: selectedNote.noteType || "",
            content: selectedNote.content || "",
            modified: selectedNote.modified || "",
            created: selectedNote.created || "",
            createdBy: selectedNote.createdBy || "",
          }}
          onSubmit={(values: any) => {
            submitFormData(values);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
          }) => {
            return (
              <div>
                <FormikForm>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        disabled={isReadOnly}
                        className="controlSize"
                        required
                        label="Snippet Title"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={4}>
                      <FormControl className="controlSize controlSpacing">
                        <InputLabel required>Note Type</InputLabel>
                        <Select
                          name="noteType"
                          value={values.noteType}
                          onChange={handleChange}
                          disabled={isReadOnly}
                          onBlur={handleBlur}
                        >
                          {noteTypes.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <TextField
                        name="content"
                        className="controlSize controlSpacing"
                        label="Note Text"
                        variant="standard"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.content}
                        disabled={isReadOnly}
                        multiline={true}
                        rows={6}
                      />
                    </Col>
                  </Row>
                </FormikForm>

                <Modal.Footer className="controlSpacing">
                  <Button
                    onClick={() => closeModal()}
                    variant="contained"
                    color="secondary"
                  >
                    Close
                  </Button>

                  {isReadOnly ? (
                    <></>
                  ) : (
                    <Button
                      className="submitBtn"
                      onClick={() => handleSubmit()}
                      variant="contained"
                      color="primary"
                    >
                      Finish
                    </Button>
                  )}
                </Modal.Footer>
              </div>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default NoteModal;
