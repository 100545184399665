export const noteTypes = [
  "Account Info",
  "Private",
  "Links",
  "Food",
  "Travel",
  "Tips",
  "Tricks",
  "Work",
  "To Do",
  "Other",
] as string[];
