/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import * as Languages from "../../../data/Languages";
import * as ValidationUtils from "../../../validations/SnippetValidations";
import Editor from "../../editor/Editor";
// import Highlight from "react-highlight";
import useApi from "../../../api/useApi";
import "./SnippetModal.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@material-ui/core";
import * as Models from "../../../models/GlobalModels";

interface SnippetModalProps {
  modalProps: Models.modalData;
  hideModalMethod: React.Dispatch<React.SetStateAction<Models.modalData>>;
  selectedSnippet: Models.Snippet;
  notificationFunc: (message: string, success: boolean) => void;
  responseMethod: (response: Models.postResponse, deletion: boolean) => void;
}

function SnippetModal({
  modalProps,
  hideModalMethod,
  selectedSnippet,
  responseMethod,
  notificationFunc,
}: SnippetModalProps) {
  const [loadError, setLoadError] = useState();
  const axios = useApi();

  const codingLangs = Languages.codingLanguages;

  const isReadOnly = (modalProps.type === "View") as boolean;

  const closeModal = () => {
    hideModalMethod({ title: "", visible: false, type: "" });
  };

  const submitFormData = (payload: Models.Snippet) => {
    const validations = ValidationUtils.validateSnippetForSubmission(
      payload
    ) as string;
    if (validations === "") {
      if (modalProps.type === "Edit") {
        updateSnippet(payload);
      } else {
        createSnippet(payload);
      }
    } else {
      notificationFunc(validations, false);
    }
  };

  const createSnippet = (payload: Models.Snippet) => {
    axios
      .post("/Snippet/CreateSnippet", payload)
      .then((response) => {
        const result = response.data as Models.postResponse;
        responseMethod(result, false);
      })
      .catch((error: Error) => {
        console.log("Error Hit");
        setLoadError(() => {
          throw error;
        });
      });
  };

  const copyToClipboard = () => {
    const element = document.getElementById("snippetText") as HTMLInputElement;
    if (element !== undefined || element !== null) {
      const elementValue = element.value as string;
      navigator.clipboard.writeText(elementValue);
      notificationFunc("Snippet Text copied to clipboard!", true);
    } else {
      notificationFunc("Failed to copy snippet text to clipboard!", false);
    }
  };

  const updateSnippet = (payload: Models.Snippet) => {
    axios
      .put("/Snippet/UpdateSnippet", payload)
      .then((response) => {
        const result = response.data as Models.postResponse;
        responseMethod(result, false);
      })
      .catch((error: Error) => {
        console.log("Error Hit");
        setLoadError(() => {
          throw error;
        });
      });
  };

  return (
    <Modal
      show={modalProps.visible}
      onHide={hideModalMethod}
      backdrop="static"
      size="lg"
    >
      <Modal.Header>
        <h2>{modalProps.title}</h2>
        {isReadOnly ? (
          <i
            className="fa fa-clipboard copyIcon"
            title="Copy Snippet Text to Clipboard"
            onClick={() => copyToClipboard()}
          ></i>
        ) : (
          <></>
        )}
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{
            id: selectedSnippet.id || 0,
            title: selectedSnippet.title || "",
            description: selectedSnippet.description || "",
            type: selectedSnippet.type || "",
            tags: selectedSnippet.tags || "",
            snippetText: selectedSnippet.snippetText || "",
            dateModified: selectedSnippet.dateModified || "",
            dateCreated: selectedSnippet.dateCreated || "",
            createdBy: selectedSnippet.createdBy || "",
          }}
          onSubmit={(values: any) => {
            submitFormData(values);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
          }) => {
            return (
              <div>
                <FormikForm>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        disabled={isReadOnly}
                        className="controlSize"
                        required
                        label="Snippet Title"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <TextField
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isReadOnly}
                        value={values.description}
                        className="controlSize controlSpacing"
                        label="Description"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={8}>
                      <TextField
                        name="tags"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tags}
                        disabled={isReadOnly}
                        className="controlSize controlSpacing"
                        required
                        label="Tags (Comma Seperated)"
                      />
                    </Col>

                    <Col xs={4}>
                      <FormControl className="controlSize controlSpacing">
                        <InputLabel required>Language</InputLabel>
                        <Select
                          name="type"
                          value={values.type}
                          onChange={handleChange}
                          disabled={isReadOnly}
                          onBlur={handleBlur}
                        >
                          {codingLangs.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <Editor
                        controlName="snippetText"
                        css="controlSize controlSpacing"
                        handleBlur={handleBlur}
                        readOnly={isReadOnly}
                        handleChange={handleChange}
                        value={values.snippetText}
                      />
                    </Col>
                  </Row>
                </FormikForm>

                <Modal.Footer className="controlSpacing">
                  <Button
                    onClick={() => closeModal()}
                    variant="contained"
                    color="secondary"
                  >
                    Close
                  </Button>

                  {isReadOnly ? (
                    <></>
                  ) : (
                    <Button
                      className="submitBtn"
                      onClick={() => handleSubmit()}
                      variant="contained"
                      color="primary"
                    >
                      Finish
                    </Button>
                  )}
                </Modal.Footer>
              </div>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default SnippetModal;
