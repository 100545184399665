import React, { useEffect, useState } from "react";
import * as Models from "../../models/GlobalModels";
import * as EnvUtils from "../../utility/envUtil";
import { Alert } from "react-bootstrap";
import axios from "axios";
import "./AttentionBanner.css";

interface AttentionBannerProps {
  showDemo?: boolean;
}

function AttentionBanner({ showDemo }: AttentionBannerProps) {
  const [alertBanner, setAlertBanner] = React.useState<
    Models.AppMessage | undefined
  >(undefined);

  const [demo, setDemo] = useState<boolean | undefined>(showDemo);

  const appKey = "3abe0476-ab9c-4821-a687-149bd63d6559" as string;

  const fetchMessage = () => {
    const request =
      `${EnvUtils.getSentinelApiBaseConnection()}/Inquiries/GetAppMessage/${appKey}` as string;
    axios
      .get(request)
      .then((response) => {
        const result = response.data as Models.AppMessage;
        if (
          result !== null &&
          result !== undefined &&
          result.message !== undefined &&
          result.message.length > 0
        ) {
          setAlertBanner(result);
        } else {
          setAlertBanner(undefined);
        }
      })
      .catch((error) => {
        //Soft handle. No crashing
        setAlertBanner(undefined);
      });
  };

  useEffect(() => {
    if (!EnvUtils.isLocalEnvironment()) {
      fetchMessage();
    }
  }, []);

  const getMessageCoding = (type: string | undefined) => {
    if (type === null || type === undefined || type.length === 0) {
      return "secondary";
    }

    switch (type) {
      case "Alert":
        return "danger";
      case "Warning":
        return "warning";
      case "Maintenance":
        return "primary";
      default:
        //Information
        return "secondary";
    }
  };

  const getMessageIcon = (type: string | undefined) => {
    if (type === null || type === undefined || type.length === 0) {
      return <i className="fa fa-exclamation-circle alertMsgIconProps" />;
    }

    switch (type) {
      case "Alert":
        return <i className="fa fa-bell alertMsgIconProps" />;
      case "Warning":
        return <i className="fa fa-exclamation-triangle alertMsgIconProps" />;
      case "Maintenance":
        return <i className="fa fa-wrench alertMsgIconProps" />;
      default:
        //Information
        return <i className="fa fa-exclamation-circle alertMsgIconProps" />;
    }
  };

  const viewDemo = demo !== undefined ? demo : (false as boolean);
  const codeKey = getMessageCoding(alertBanner?.messageType) as string;
  const iconElement = getMessageIcon(alertBanner?.messageType) as JSX.Element;
  const messageText =
    alertBanner === undefined
      ? "Test Message"
      : (alertBanner?.message as string);

  const handleClose = () => {
    if (viewDemo) {
      setDemo(undefined);
    }
    setAlertBanner(undefined);
  };

  return (
    <>
      {alertBanner !== undefined || viewDemo ? (
        <Alert
          key={codeKey}
          variant={codeKey}
          onClose={() => handleClose()}
          dismissible
        >
          <p className="alertMsgProps">
            {iconElement}
            {messageText}
          </p>
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}

export default AttentionBanner;
