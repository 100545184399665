import React from "react";
import "./NoNote.css";

function NoNote() {
  return (
    <div>
      <p className="centerTileText">
        <i className="fa fa-sticky-note tileIconSize tileColor"></i>
      </p>
      <h3 className="centerTileText tileAlignment tileColor">
        You have no Notes.
      </h3>
    </div>
  );
}

export default NoNote;
