import React from "react";
import "./Footer.css";

function Footer() {
  const currYear = new Date().getFullYear() as number;

  return (
    <div className="footerProps">
      <p className="centerText footerText">
        <i className="fa fa-copyright"></i> {currYear} ANTF Group.
      </p>
    </div>
  );
}

export default Footer;
