import React from "react";
import axios, { AxiosInstance } from "axios";
import { useKeycloak } from "@react-keycloak/web";
import * as envMgr from "../utility/envUtil";

const useApi = (): AxiosInstance => {
  const { keycloak } = useKeycloak();

  return React.useMemo(
    () =>
      axios.create({
        baseURL: envMgr.getApiBaseConnection(),
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      }),
    [keycloak]
  );
};

export default useApi;
