import React from "react";
import * as TableStyles from "../../../../themes/TableTheme";
import GeneralRowActions from "../GeneralRowActions";

interface noteRowProps {
  index: number;
  title: string;
  type: string;
  modified: string;
  editMethod: (recordId: number) => void;
  deleteMethod: (recordId: number) => void;
  viewMethod: (recordId: number) => void;
}

function NoteRow({
  index,
  title,
  type,
  modified,
  editMethod,
  viewMethod,
  deleteMethod,
}: noteRowProps) {
  return (
    <TableStyles.StyledTableRow id="rowData" key={index}>
      <TableStyles.StyledTableCell align="left" component="th" scope="row">
        {title}
      </TableStyles.StyledTableCell>
      <TableStyles.StyledTableCell align="center">
        {type}
      </TableStyles.StyledTableCell>
      <TableStyles.StyledTableCell align="center">
        {modified}
      </TableStyles.StyledTableCell>
      <TableStyles.StyledTableCell align="right">
        <GeneralRowActions
          index={index}
          indexName={title}
          editAction={editMethod}
          deleteAction={deleteMethod}
          viewAction={viewMethod}
        />
      </TableStyles.StyledTableCell>
    </TableStyles.StyledTableRow>
  );
}

export default NoteRow;
