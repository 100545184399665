/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DummyAvatar from "../../assets/DefaultAvatar.png";
import useApi from "../../api/useApi";
import * as Models from "../../models/GlobalModels";
import * as NoteCategory from "../../data/NoteCat";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useHistory } from "react-router-dom";
import TagsDropdown from "../dropdowns/tagsDropdown/TagsDropdown";
import "./Header.css";

interface HeaderProps {
  sideBarToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setPageNameFunc: React.Dispatch<React.SetStateAction<string>>;
  currentPage: string;
  setTagFunc: React.Dispatch<React.SetStateAction<string>>;
  selTag: string;
  refresh: boolean;
  refreshFunc: React.Dispatch<React.SetStateAction<boolean>>;
  loadingView: boolean;
  errorHandlerFunc: React.Dispatch<React.SetStateAction<Error | undefined>>;
  profileData: Models.userProfile;
}

function Header({
  sideBarToggle,
  setPageNameFunc,
  currentPage,
  setTagFunc,
  refresh,
  refreshFunc,
  loadingView,
  selTag,
  profileData,
  errorHandlerFunc,
}: HeaderProps) {
  const [uniqueTags, setUniqueTags] = useState<Models.tagsPayload>({
    success: false,
    tags: [],
  });

  const history = useHistory();
  const axios = useApi();

  const navigateToProfile = () => {
    history.push("./profile");
    setPageNameFunc("My Profile");
  };

  const tagClick = (tag: string) => {
    setTagFunc(tag);
  };

  const getUniqueTags = () => {
    axios
      .get("/Snippet/GetUniqueTags")
      .then((response) => {
        const result = response.data as Models.tagsPayload;
        setUniqueTags(result);
        checkUniqueTags();
        if (refresh) {
          refreshFunc(false);
        }
      })
      .catch((error: Error) => {
        console.log("Error Hit");
        errorHandlerFunc(() => {
          throw error;
        });
      });
  };

  const populateNoteTypes = () => {
    setUniqueTags({ success: true, tags: NoteCategory.noteTypes });
    if (refresh) {
      refreshFunc(false);
    }
  };

  const checkUniqueTags = () => {
    if (!uniqueTags.tags.includes(selTag)) {
      setTagFunc("ALL");
    }
  };

  useEffect(() => {
    setUniqueTags({ success: true, tags: [] });
    if (currentPage === "Notes") {
      populateNoteTypes();
    } else {
      getUniqueTags();
    }
  }, [refresh]);

  return (
    <div>
      {profileData.avatar !== "" ? (
        <img
          src={profileData.avatar}
          className="w3-circle w3-right w3-margin w3-hover-opacity headerImgProps"
          alt="Avatar"
          title="Account Options"
          onClick={() => navigateToProfile()}
        />
      ) : (
        <img
          src={DummyAvatar}
          className="w3-circle w3-right w3-margin w3-hover-opacity headerImgProps"
          alt="Avatar"
          title="Account Options"
          onClick={() => navigateToProfile()}
        />
      )}

      <span className="w3-button w3-hide-large w3-xxlarge w3-hover-text-grey">
        <i className="fa fa-bars" onClick={() => sideBarToggle(true)}></i>
      </span>

      <div>
        {currentPage === "My Profile" ? (
          <div className="w3-container noBarPadding"></div>
        ) : (
          <div>
            {loadingView ? (
              <div>
                <div className="w3-container w3-hide-small w3-hide-medium">
                  <ButtonGroup
                    variant="outlined"
                    aria-label="text button group"
                  >
                    <Button
                      onClick={() => tagClick("All")}
                      className={
                        selTag.toLowerCase() === "all" ? "highlightedTag" : ""
                      }
                    >
                      All
                    </Button>
                    {uniqueTags.tags.slice(0, 10).map((item) => (
                      <Button
                        key={Math.random()}
                        className={
                          selTag.toLowerCase() === item.toLowerCase()
                            ? "highlightedTag"
                            : ""
                        }
                        onClick={() => tagClick(item)}
                      >
                        {item}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
                <div className="tagsDropdownPos w3-hide-large">
                  <TagsDropdown
                    selectedTag={selTag}
                    tags={uniqueTags}
                    handleSelection={tagClick}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
